<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">마감년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "closeYearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilter.closeYm"
                    type="lookup-condition"
                    @change="onYearMonthChange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">창고</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect v-model="searchFilter.wrhous"
                                 :dataSource="commonCodeOptions.wrhousData"
                                 :fields="commonCodeOptions.fields"
                                 :allowFiltering="false"
                                 cssClass="lookup-condition-multiselect"
                                 width="250"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">{{ searchFilter.closeYm }}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
              <ul class="lookup-button">
                <li class="print"><erp-button button-div="PRINT" @click.native="onClickPrint" :is-shortcut-button="true">인쇄</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit, Aggregate} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import moment from "moment/moment";
import {DATE_FORMAT_YYYY_MM} from "@/utils/date";
import InputDate from "@/components/common/datetime/InputDate";
import StockSuppliesStat from "@/api/v2/StockManage/StockSuppliesStat";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "SuppliesMonthlyInvntry",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputDate
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      //최근 마감월
      lastCloseYm : null,
      //조회 필드
      searchFilter: {
        closeYm:null,
        wrhous: [],
      },
      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        fields: { text: "comName", value: "comCode" },
      },
      //그리드 필드
      gridField: {
        title: "월 수불부",
        count : 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      return {
        columns: [
          { field: "pathDesc",      textAlign: 'left',   allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "분류", },
          { field: "suppliesCode",  textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "저장품코드",isPrimaryKey: true, },
          { field: "suppliesName",  textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "저장품", },
          { field: "unit",          textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "단위", },
          {
            headerText: "전월",
            columns: [
              { field: "lsmthPrice",    textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "lsmthStock",    textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "lsmthAmt",      textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          { field: "closePrice",    textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "단가", isNumericType: true,},
          {
            headerText: "입고",
            columns: [
              { field: "wrhousngStock",   textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "wrhousngPrice",   textAlign: 'right',  allowEditing: false, width: 100,   visible: false,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "wrhousngAmt",     textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "반품",
            columns: [
              { field: "rtngudStock",   textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "rtngudPrice",   textAlign: 'right',  allowEditing: false, width: 100,   visible: false,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "rtngudAmt",     textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "출고",
            columns: [
              { field: "dlivyStock",    textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              { field: "dlivyPrice",    textAlign: 'right',  allowEditing: false, width: 100,   visible: false,  type: "number",  headerText: "단가", isNumericType: true,},
              { field: "dlivyAmt",      textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          },
          {
            headerText: "재고",
            columns: [
              { field: "closeStock",    textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
              /*{ field: "closePrice",    textAlign: 'right',  allowEditing: false, width: 100,   visible: false,  type: "number",  headerText: "단가", isNumericType: true,},*/
              { field: "closeAmt",      textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "number",  headerText: "금액", isNumericType: true,},
            ],
          }
        ],
        aggregates:[
          {
            columns: [
              { field: 'pathDesc',     aggregationType: 'TotalCaption', customAggregate: '합계',},
              { field: "lsmthAmt",     aggregationType: 'TotalSum',  },
              { field: "wrhousngAmt",  aggregationType: 'TotalSum',  },
              { field: "rtngudAmt",    aggregationType: 'TotalSum',  },
              { field: "dlivyAmt",     aggregationType: 'TotalSum',  },
              { field: "closeAmt",     aggregationType: 'TotalSum',  },
            ],
          },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit, Aggregate],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //기초데이터 세팅
    async initData() {
      this.lastCloseYm =  await StockCommon.getLastSuppliesClosingYm();
      this.searchFilter.closeYm = this.lastCloseYm ? moment(this.lastCloseYm).format(DATE_FORMAT_YYYY_MM) : moment().format(DATE_FORMAT_YYYY_MM);
      // 창고(저장품 창고만)--이력이니 삭제된것도
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N',authoFlag:'N',suppliesFlag:'Y'});
      //await this.onClickSearch();
    },
    async onYearMonthChange(){
      await this.onClickSearch();
    },
    //조회
    async onClickSearch() {
      // 조회조건에 있는 창고만(권한창고)
      this.searchFilter.wrhousList = this.searchFilter.wrhous.length > 0 ? this.searchFilter.wrhous.join('|') : this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|');
      // 창고 array 객체 삭제.
      const args = Object.assign({...this.searchFilter},{wrhous:null});
      this.gridField.dataSource = await StockSuppliesStat.getSuppliesMonthlyInvntryList(args);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //리포트 출력
    async onClickPrint() {
      if(this.gridField.dataSource.length <1){
        this.errorToast("출력할 자료가 없습니다.");
        return;
      }
      const gridData = [];
      this.gridField.dataSource.forEach((item,index)=> {
        let tempParentCode = this.gridField.dataSource[index + 1]?.parentCode;
        let tempClassCode = this.gridField.dataSource[index + 1]?.classCode;
        const data = {
          ...item,
          // 합계 계산- 저장품의 분류체계 단계가 고정이 아니므로 full path를 보여준다(아래 주석처리함)
          //pathDesc : item.pathDesc.toString().split(">")[2] + item.pathDesc.toString().split(">")[3]
        };
        gridData.push(data);
        // 소계 계산(다음행 코드와 다를경우)
        if (item.classCode !== tempClassCode) {
          const subTotal = {};
          subTotal.suppliesCode = "소계";
          subTotal.lsmthStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.lsmthStock, 0);
          subTotal.lsmthAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.lsmthAmt, 0);
          subTotal.wrhousngStock    =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.wrhousngStock, 0);
          subTotal.wrhousngAmt      =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.wrhousngAmt, 0);
          subTotal.rtngudStock      =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.rtngudStock, 0);
          subTotal.rtngudAmt        =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.rtngudAmt, 0);
          subTotal.dlivyStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.dlivyStock, 0);
          subTotal.dlivyAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.dlivyAmt, 0);
          subTotal.closeStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.closeStock, 0);
          subTotal.closeAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.closeAmt, 0);
          gridData.push(subTotal);
        }
        // 합계 계산- 저장품의 분류체계 단계가 고정이 아니므로 합산은 일단 제외한다.
        /*if (item.parentCode !== tempParentCode) {
          const total = {};
          total.suppliesCode = "합계";
          total.lsmthStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.lsmthStock, 0);
          total.lsmthAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.lsmthAmt, 0);
          total.wrhousngStock    =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.wrhousngStock, 0);
          total.wrhousngAmt      =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.wrhousngAmt, 0);
          total.rtngudStock      =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.rtngudStock, 0);
          total.rtngudAmt        =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.rtngudAmt, 0);
          total.dlivyStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.dlivyStock, 0);
          total.dlivyAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.dlivyAmt, 0);
          total.closeStock       =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.closeStock, 0);
          total.closeAmt         =  this.gridField.dataSource.filter(it=> it.classCode==item.classCode).reduce((acc, cur) => acc + cur.closeAmt, 0);
          gridData.push(total);
        }*/
      });
      // 총계 계산
      const allTotal = {};
      allTotal.suppliesCode = "총계";
      allTotal.lsmthStock       =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.lsmthStock, 0);
      allTotal.lsmthAmt         =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.lsmthAmt, 0);
      allTotal.wrhousngStock    =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.wrhousngStock, 0);
      allTotal.wrhousngAmt      =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.wrhousngAmt, 0);
      allTotal.rtngudStock      =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.rtngudStock, 0);
      allTotal.rtngudAmt        =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.rtngudAmt, 0);
      allTotal.dlivyStock       =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.dlivyStock, 0);
      allTotal.dlivyAmt         =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.dlivyAmt, 0);
      allTotal.closeStock       =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.closeStock, 0);
      allTotal.closeAmt         =  this.gridField.dataSource.reduce((acc, cur) => acc + cur.closeAmt, 0);
      gridData.push(allTotal);

      const reportData = {
        reportJson: {
          jsonData: {
            gridData: gridData,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions: moment(this.searchFilter.closeYm).format("yyyy년 MM월") + `  창고명: ${this.searchFilter.wrhous.length > 0 ? this.commonCodeOptions.wrhousData.filter(item =>  this.searchFilter.wrhous.includes(item.comCode)).map(data => data.comName).join(', ') : "전체"}`,
        },
      };
      await this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 10px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 10px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>
